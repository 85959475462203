import React, { useState, useRef, useEffect } from 'react';
import './App.css';
import uploadImg from "./upload.png";

function App() {
  const [selectedImage, setSelectedImage] = useState(null);
  const canvasRef = useRef(null);
  const [ctx, setCtx] = useState(null);
  const [fillColor, setFillColor] = useState({ r: 0, g: 0, b: 0, a: 255 }); // Default to black
  const [undoStack, setUndoStack] = useState([]);
  const [redoStack, setRedoStack] = useState([]);

  
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const screenWidth = window.innerWidth;
      console.log(screenWidth)
      let img = new Image();
      img.onload = () => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
     
  
        // Calculate scale to fit the image within screen width while maintaining aspect ratio
        const scale = Math.min(screenWidth / img.width, 1); // Ensure scale is not more than 1
  
        // Set the canvas width and height to the scaled dimensions
        canvas.width = img.width * scale;
        canvas.height = img.height * scale;
        console.log(`"스크린 가로 ${screenWidth}"`)
        console.log(`"컨버스 가로 ${canvas.width}"`)
        console.log(`"이미지 가로 ${img.width}"`)
  
        // Draw the image on the canvas with the new dimensions
        ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear the canvas before drawing
        
        
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        setCtx(ctx);

      };
      img.src = URL.createObjectURL(event.target.files[0]);

      setSelectedImage(img);
    }
  };

  const saveStateToUndo = () => {
    const imageData = ctx.getImageData(0, 0, canvasRef.current.width, canvasRef.current.height);
    setUndoStack([...undoStack, imageData]);
    // Clear the redo stack whenever a new action is taken
    setRedoStack([]);
  };

  const undoLastAction = () => {
    setUndoStack(prevUndoStack => {
      if (prevUndoStack.length > 0) {
        const lastState = prevUndoStack.pop();
        setRedoStack(prevRedoStack => [...prevRedoStack, ctx.getImageData(0, 0, canvasRef.current.width, canvasRef.current.height)]);
        ctx.putImageData(lastState, 0, 0);
        return [...prevUndoStack];
      }
      return prevUndoStack;
    });
  };

  const redoLastAction = () => {
    setRedoStack(prevRedoStack => {
      if (prevRedoStack.length > 0) {
        const lastState = prevRedoStack.pop();
        setUndoStack(prevUndoStack => [...prevUndoStack, ctx.getImageData(0, 0, canvasRef.current.width, canvasRef.current.height)]);
        ctx.putImageData(lastState, 0, 0);
        return [...prevRedoStack];
      }
      return prevRedoStack;
    });
  };

  // Convert hex color to RGBA object
  const hexToRGBA = (hex) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return { r, g, b, a: 255 };
  };

  const floodFillAndUpdateHistory = (startX, startY, fillColor) => {
    saveStateToUndo();
    floodFill(startX, startY, fillColor);
  };

  const floodFill = (startX, startY, fillColor, tolerance) => {
    const startTime = Date.now();
    const maxDuration = 3000; // Maximum duration in milliseconds

    const imageData = ctx.getImageData(
      0,
      0,
      canvasRef.current.width,
      canvasRef.current.height
    );
    const targetColor = getColorAtPixel(imageData, startX, startY);

    if (colorsMatch(targetColor, fillColor)) {
      return; // No need to fill if the target color is the same as the fill color
    }

    const pixelStack = [[startX, startY]];

    while (pixelStack.length) {
      const currentTime = Date.now();
      if (currentTime - startTime > maxDuration) {
        console.log("Flood fill operation timed out.");
        break; // Stop the function if more than 3 seconds have passed
      }

      const newPos = pixelStack.pop();
      const x = newPos[0];
      let y = newPos[1];
      let pixelPos = (y * imageData.width + x) * 4;

      // Move up until we hit a different color or the top of the canvas
      while (
        y >= 0 &&
        colorsMatch(getColorAtPixel(imageData, x, y), targetColor, tolerance)
      ) {
        y--;
        pixelPos -= imageData.width * 4;
      }
      pixelPos += imageData.width * 4;
      y++;

      let reachLeft = false;
      let reachRight = false;

      // Move down the canvas, filling as we go
      while (
        y < imageData.height &&
        colorsMatch(getColorAtPixel(imageData, x, y), targetColor, tolerance)
      ) {
        setColorAtPixel(imageData, x, y, fillColor);

        if (x > 0) {
          if (colorsMatch(getColorAtPixel(imageData, x - 1, y), targetColor, tolerance)) {
            if (!reachLeft) {
              pixelStack.push([x - 1, y]);
              reachLeft = true;
            }
          } else if (reachLeft) {
            reachLeft = false;
          }
        }

        if (x < imageData.width - 1) {
          if (colorsMatch(getColorAtPixel(imageData, x + 1, y), targetColor,tolerance)) {
            if (!reachRight) {
              pixelStack.push([x + 1, y]);
              reachRight = true;
            }
          } else if (reachRight) {
            reachRight = false;
          }
        }

        y++;
      }
    }
    ctx.putImageData(imageData, 0, 0);
  };

  const getColorAtPixel = (imageData, x, y) => {
    const { width, data } = imageData;
    const index = (x + y * width) * 4;
    return {
      r: data[index],
      g: data[index + 1],
      b: data[index + 2],
      a: data[index + 3],
    };
  };

  const setColorAtPixel = (imageData, x, y, color) => {
    const { width, data } = imageData;
    const index = (x + y * width) * 4;
    data[index] = color.r;
    data[index + 1] = color.g;
    data[index + 2] = color.b;
    data[index + 3] = color.a;
  };

  const colorsMatch = (a, b, tolerance = 30) => {
    return (
      Math.abs(a.r - b.r) <= tolerance &&
      Math.abs(a.g - b.g) <= tolerance &&
      Math.abs(a.b - b.b) <= tolerance
      // You may also want to compare the alpha values with a tolerance if needed
      // Math.abs(a.a - b.a) <= tolerance
    );
  };

  const openCanvasInNewWindow = () => {
    // Ensure the canvas reference is current and the canvas has a context
    if (canvasRef.current) {
      // Get data URL of the canvas content
      const dataUrl = canvasRef.current.toDataURL('image/png');
  
      // Create a new window or tab
      const newWindow = window.open();
      newWindow.document.write(`<img src="${dataUrl}" width="${canvasRef.current.width}" height="${canvasRef.current.height}"/>`);
    }
  };

  return (
    <div className="App">
    <h2>YULYULMAN COLORING</h2>
    <div className="input-wrapper">
      <div className="file-upload">
        {/* Wrap the image with a label and use the htmlFor prop to associate it with the input field */}
        <label htmlFor="file-upload-input">
          <img src={uploadImg} alt="upload" className="upload-icon"/>
          <h3>Click Icon to upload</h3>
        </label>
        {/* Hide the file input, but make it functional */}
        <input
          id="file-upload-input"
          type="file"
          onChange={onImageChange}
          style={{ display: 'none' }}
        />
      </div>
      
      <div className="icons">
      <input
    type="color"
    onChange={(e) => setFillColor(hexToRGBA(e.target.value))}
    className="color-picker"
  />

  {/* Undo button with icon */}
  <button onClick={undoLastAction} className="icon-button">
    <img src={require('./undo.png')} alt="Undo" />
  </button>

  {/* Redo button with icon */}
  <button onClick={redoLastAction} className="icon-button">
    <img src={require('./redo.png')} alt="Redo" />
  </button>
  <button onClick={openCanvasInNewWindow} className="icon-button">
          <img src={require('./new-window-icon.png')} alt="New Window" />
        </button>

  </div>
    </div>
    <canvas
    ref={canvasRef}
    onClick={(e) => {
      const rect = canvasRef.current.getBoundingClientRect();
      const scaleX = canvasRef.current.width / rect.width;
      const scaleY = canvasRef.current.height / rect.height;
      const x = (e.clientX - rect.left) * scaleX;
      const y = (e.clientY - rect.top) * scaleY;
      floodFillAndUpdateHistory(Math.floor(x), Math.floor(y), fillColor);
    }}
  />

    </div>
  );
}

export default App;
